.Button {
    width: 242px;
    height: 28px;
}

.pinkColor {
    background-color: pink;
}

.blueColor {
    background-color: lightblue;
}