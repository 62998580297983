.App-logo {
  border-radius: 50%;
  height: 270px;
  width: 200px;
  margin-right: 20px;

}

.biography {
  width: 26.25rem;
}

.photo-and-bio {
  display: flex;
  align-items: center;
  padding: 200px;
  margin-left: 300px;
  margin-top: 100px;
}